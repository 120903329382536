import { SITE_URL } from "../../cms/env"
import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import SEO from "../seo"
import Disqus from "disqus-react"
import Layout from "../layout"
import {
  ContentWrapper,
  ContentHeader,
  ContentInfo,
  ContentDate,
  ContentTitle,
  ContentBody,
  ContentText,
} from "../parts/content"
import Tags from "./tags"

const BlogPost = ({ data, pageContext }) => {
  const node = data.markdownRemark
  const disqusConfig = {
    url: `${SITE_URL}${node.fields.slug}`,
    identifier: node.id,
    title: node.frontmatter.title,
  }
  return (
    <Layout>
      <SEO title={node.frontmatter.title} description={node.excerpt}></SEO>
      <ContentWrapper key={`blod-post-${node.id}`}>
        <ContentHeader>
          <ContentInfo>
            <ContentDate className="font-pixel">
              {node.frontmatter.date}
            </ContentDate>
          </ContentInfo>
          <ContentTitle tag="h1">{node.frontmatter.title}</ContentTitle>
        </ContentHeader>
        <ContentBody type="detail">
          {node.frontmatter.heading_image &&
          !node.html.match(node.frontmatter.heading_image) ? (
            <p>
              <img
                src={node.frontmatter.heading_image}
                alt={node.frontmatter.heading_image}
              ></img>
            </p>
          ) : (
            <></>
          )}
          <ContentText
            dangerouslySetInnerHTML={{ __html: node.html }}
          ></ContentText>
        </ContentBody>
        <Tags list={pageContext.tagList} values={node.frontmatter.tags}></Tags>
        <div
          css={css`
            padding-top: 15px;
            margin: 0 15px;
          `}
        >
          <Disqus.DiscussionEmbed
            shortname={process.env.GATSBY_DISQUS_SHORT_NAME}
            config={disqusConfig}
          />
        </div>
      </ContentWrapper>
    </Layout>
  )
}
export default BlogPost;

export const query = graphql`
  query($slug: String!) {
    adminYaml {
      collections {
        name
        fields {
          name
          label
          widget
          required
          options {
            label
            value
          }
        }
      }
    }
    markdownRemark(fields: { pagetype: { eq: "posts" }, slug: { eq: $slug } }) {
      id
      frontmatter {
        title
        date(formatString: "YYYY.MM.DD")
        category
        tags
        heading_image
      }
      html
      excerpt
      fields {
        slug
      }
    }
  }
`
