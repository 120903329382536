import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

const Tags = ({ list, values }) => {
  values = values || []
  values = list.reduce((carry, item) => {
    if (values.includes(item.value)) {
      carry.push(item.value)
    }
    return carry
  }, [])
  return (
    <div
      css={css`
        width: 100%;
        height: 30px;
        margin: 0px;
        padding: 3px 0;
        background-color: #393e46;
        color: #ffffff;
        border-color: #ffffff;
      `}
    >
      <span
        className="font-pixel"
        css={css`
          display: inline-block;
          vertical-align: bottom;
          font-size: 0.7em;
          margin: 0 6px;
          width: 24px;
          height: 24px;
        `}
      >
        <img
          css={css`
            margin: 0;
            padding: 0;
          `}
          src="/assets/icons/tag.png"
          alt="Tag"
        ></img>
      </span>
      {values.map(val => {
        return (
          <span
            css={css`
              display: inline-block;
              vertical-align: middle;
              line-height: 24px;
              font-size: 0.8em;
              padding: 0 6px 0 2px;
              margin-right: 4px;
              border-top: none;
              border-bottom: none;
              font-weight: bolder;
              border-left: 4px solid #00adb5;
              height: 24px;
            `}
            key={`tag_${val}`}
          >
            <Link
              to={`/tags/${val}`}
              css={css`
                color: #ffffff;
                text-decoration-color: #ffffff;
              `}
            >
              {list.filter(tag_conf => tag_conf.value === val)[0].label}
            </Link>
          </span>
        )
      })}
    </div>
  )
}
export default Tags
